const routes = [
  {
    path: '/',
    name: 'screensaver',
    component: () => import('../views/Screensaver.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Index.vue'),
  },
  {
    path: '/client-card',
    name: 'client-card',
    component: () => import('../views/Clientcard.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue'),
  },
  {
    path: '/cart/summary',
    name: 'cart-summary',
    component: () => import('../views/cart/Summary.vue'),
  },
  {
    path: '/cart/payment-methods',
    name: 'cart-payment-methods',
    component: () => import('../views/cart/PaymentMethods.vue'),
  },
  {
    path: '/cart/app-payment',
    name: 'cart-app-payment',
    component: () => import('../views/cart/AppPayment.vue'),
  },
  {
    path: '/cart/checkout',
    name: 'cart-checkout',
    component: () => import('../views/cart/Checkout.vue'),
  },
  {
    path: '/cart/thank-you',
    name: 'cart-thank-you',
    component: () => import('../views/cart/ThankYou.vue'),
  },
  {
    path: '/cart/thank-you-app',
    name: 'cart-thank-you-app',
    component: () => import('../views/cart/ThankYouApp.vue'),
  },
];

export default routes;
